
/* eslint-disable */
import debounce from 'lodash/debounce'

const HeroSection = () => import('~/containers/LandingPage/HeroSection.vue')
const SectionBuildYourContent = () => import('~/containers/LandingPage/SectionBuildYourContent.vue')
const SectionCreateAndEarnV2 = () => import('~/containers/LandingPage/SectionCreateAndEarnV2.vue')
const SectionAsFeaturedOn = () => import('~/containers/LandingPage/SectionAsFeaturedOn.vue')
const SectionForTheOne = () => import('~/containers/LandingPage/SectionForTheOne.vue')
const SectionHearFromCommunityV2 = () => import('~/containers/LandingPage/SectionHearFromCommunityV2.vue')
const SectionGetUsername = () => import('~/containers/LandingPage/SectionGetUsername.vue')
const SectionLevelUp = () => import('~/containers/LandingPage/SectionLevelUp.vue')

const backgroundVideos = [
  // { src: 'https://storage.googleapis.com/gank-production/publics/background/gank-hero-background-update.mp4', type: 'video/mp4' },
  // { src: 'https://storage.googleapis.com/gank-production/publics/background/gank-hero-background-update.mov', type: 'video/mov' },
  // { src: 'https://storage.googleapis.com/gank-production/publics/background/gank-hero-background-update.webm', type: 'video/webm' },
  {
    src: 'https://storage.googleapis.com/gank-production/publics/background/landing-background.mp4', type: 'video/mp4',
  },
  {
    src: 'https://storage.googleapis.com/gank-production/publics/background/landing-background.mov', type: 'video/mov',
  },
  {
    src: 'https://storage.googleapis.com/gank-production/publics/background/landing-background.webm', type: 'video/webm',
  },
  // {
  //   src: 'https://storage.googleapis.com/gank-production/publics/background/gank-hero-mobile-background.mp4', type: 'video/mp4', media: '(orientation: portrait) and (max-width:480px)', key: 'mobile',
  // },
  // {
  //   src: 'https://storage.googleapis.com/gank-production/publics/background/gank-hero-mobile-background.mov', type: 'video/mov', media: '(orientation: portrait) and (max-width:480px)', key: 'mobile',
  // },
  // {
  //   src: 'https://storage.googleapis.com/gank-production/publics/background/gank-hero-mobile-background.webm', type: 'video/webm', media: '(orientation: portrait) and (max-width:480px)', key: 'mobile',
  // },
]

const backgroundVideosMobile = [
  { src: 'https://storage.googleapis.com/gank-production/publics/background/gank-hero-mobile-background.mp4', type: 'video/mp4', media: '(orientation: potrait) and (max-width:480px)' },
  { src: 'https://storage.googleapis.com/gank-production/publics/background/gank-hero-mobile-background.mov', type: 'video/mov', media: '(orientation: potrait) and (max-width:480px)' },
  { src: 'https://storage.googleapis.com/gank-production/publics/background/gank-hero-mobile-background.webm', type: 'video/webm', media: '(orientation: potrait) and (max-width:480px)' },
]

export default {
  components: {
    HeroSection,
    SectionBuildYourContent,
    SectionCreateAndEarnV2,
    SectionAsFeaturedOn,
    SectionForTheOne,
    SectionHearFromCommunityV2,
    SectionGetUsername,
    SectionLevelUp,
  },
  data() {
    return {
      backgroundVideos,
      backgroundVideosMobile,
      isMobile: false,
    }
  },
  computed: {
    videoSourceType() {
      if (this.isMobile) return this.backgroundVideosMobile

      return this.backgroundVideos
    },
  },
  mounted() {
    document.body.addEventListener('click', this.handleVideoPlay)
    document.body.addEventListener('touchstart', this.handleVideoPlay)

    window.addEventListener('resize', this.forceRender)
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.handleVideoPlay)
    document.body.removeEventListener('touchstart', this.handleVideoPlay)

    window.removeEventListener('resize', this.forceRender)
  },
  methods: {
    scrollToElement() {
      const el = this.$el.getElementsByClassName('create-and-earn-section')[0]
      const headerOffset = 200
      const elementPosition = el.getBoundingClientRect().top
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset

      if (el) {
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        })
      }

      this.$gtm.push({
        event: 'landing_navigation',
      })
    },
    handleVideoPlay() {
      const videoElement = document.getElementById('landingpageVideo')
      if (videoElement.playing) {
        //
      } else {
        videoElement.play()
      }
    },
    forceRender: debounce(async function _(event) {
      if (event.target.innerWidth < 640) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    }, 2000),
  },
}
